<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-06 15:39:56
 * @LastEditTime: 2023-06-30 11:14
 * @Descripttion: 教师管理
-->
<style lang="scss" scoped>
.teacher {
  @include innerPage(40px 32px 48px 15px);
  @include pageTitle(0);
  @include defalutTable(calc(100% - 194px), 48px);
  @include pageFoot;

  .page-title {
    align-items: flex-start;
    flex-wrap: wrap;
    position: relative;

    .search-form {
      width: 37.8%;
      min-width: 594px;

      .el-form {
        width: calc(100% - 114px);
      }
    }

    .tool {
      width: 100%;
      flex-grow: 1;
      flex-shrink: 0;
      margin-top: 124px;
      box-sizing: border-box;
      padding-left: 14px;
      @include flexBox(space-between);
    }
  }

  &-statistics {
    width: 954px;
    height: 90px;
    position: absolute;
    top: -4px;
    left: 50%;
    transform: translateX(-50%);
    @include flexBox(space-between);

    li {
      width: 228px;
      height: 100%;
      border-radius: 10px;
      box-sizing: border-box;
      padding: 0 26px 0 30px;
      @include flexBox(space-between);

      .statistics-info {
        color: #fff;
        flex-direction: column;
        line-height: 24px;
        @include flexBox(center, flex-start);

        .pf_bold {
          font-size: 30px;
          line-height: 40px;

          .pf {
            font-size: 14px;
            margin-left: 2px;
          }
        }
      }

      .icon-box {
        width: 66px;
        height: 66px;
        border-radius: 20px;
        @include flexBox(center);
      }

      &:nth-child(1) {
        @include dotBgPurple;

        .icon-box {
          background: rgba($color: #8773ed, $alpha: 0.6);
          box-shadow: 0px 0px 57px 0px rgba(22, 7, 157, 0.5);
        }
      }

      &:nth-child(2) {
        @include dotBgGarygreen;

        .icon-box {
          background: rgba($color: #52b9ad, $alpha: 0.87);
          box-shadow: 0px 0px 57px 0px rgba(4, 75, 61, 0.5);
        }
      }

      &:nth-child(3) {
        @include dotBgOrangered;

        .icon-box {
          background: rgba($color: #e07263, $alpha: 1);
          box-shadow: 0px 0px 57px 0px rgba(141, 17, 0, 0.5);
        }
      }

      &:nth-child(4) {
        @include dotBgOrange;

        .icon-box {
          background: rgba($color: #f68955, $alpha: 0.79);
          box-shadow: 0px 0px 57px 0px rgba(143, 50, 2, 0.5);
        }
      }
    }
  }
}

.dialog-container {
  width: 100%;
  box-sizing: border-box;
  padding: 42px 0 22px;

  .tips {
    color: #e58181;
    height: 24px;
    position: absolute;
    top: 24px;
    right: 120px;
    font-size: 14px;
    @include flexBox;

    .iconfont {
      font-size: 20px;
      margin-right: 4px;
    }
  }

  .form-wrapper {
    max-height: 560px;
    box-sizing: border-box;
    padding-right: 64px;
    overflow: hidden;
    overflow-y: auto;
  }

  .el-form {
    justify-content: space-between;
  }

  .el-form-item {
    width: 430px;
    margin-bottom: 20px;

    &.class-distribution {
      flex-grow: 1;
      width: 100%;
      align-items: flex-start;
    }
  }

  .distribution {
    &-list {
      width: 100%;
      min-height: 46px;
    }

    &-item {
      width: 100%;
      height: 46px;
      margin-bottom: 20px;
      position: relative;

      &:nth-last-child(2) {
        margin-bottom: 28px;
      }

      .del {
        position: absolute;
        top: 4px;
        right: -44px;
      }
    }

    &-form {
      justify-content: space-between;
      flex-wrap: nowrap;

      .el-form-item {
        width: 258px;
        margin-bottom: 0;
      }
    }

    &--add {
      width: 32px;
      height: 32px;
      background: #6c4ecb;
      text-align: center;
      line-height: 32px;
      color: #f5f7fe;
      border-radius: 50%;
      font-size: 20px;
      margin-top: 7px;
      cursor: pointer;
    }
  }
}
.file-list {
  .file-item {
    height: 60px;
    margin-bottom: 10px;
    @include flexBox(space-between);

    &:last-child {
      margin-bottom: 0;
    }

    .iconfont-color {
      font-size: 52px;
      flex-shrink: 0;
      line-height: 60px;
      flex-shrink: 0;
    }

    .el-button {
      flex-shrink: 0;
      margin-bottom: 6px;
    }

    .name {
      color: #212122;
      flex-grow: 1;
      max-width: 328px;
      margin: 0 20px;
    }
  }
}

::v-deep .el-dialog__footer {
  width: calc(100% - 136px);
  margin-left: 36px;
}
</style>

<template>
  <section class="teacher">
    <div class="page-title">
      <h3>教师管理</h3>
      <ul class="teacher-statistics">
        <li>
          <div class="statistics-info">
            <p class="pf_bold">{{ statistics.allcount }}</p>
            <p class="light">教师总数</p>
          </div>
          <p class="icon-box">
            <img src="@assets/images/student-icon1.png" alt="" />
          </p>
        </li>
        <li>
          <div class="statistics-info">
            <p class="pf_bold">{{ statistics.year_count }}</p>
            <p class="light">今年新增教师</p>
          </div>
          <p class="icon-box">
            <img src="@assets/images/teacher-icon1.png" alt="" />
          </p>
        </li>
        <li>
          <div class="statistics-info">
            <p class="pf_bold">{{ statistics.identity_count }}</p>
            <p class="light">班主任人数</p>
          </div>
          <p class="icon-box">
            <img src="@assets/images/teacher-icon2.png" alt="" />
          </p>
        </li>
        <li>
          <div class="statistics-info">
            <p class="pf_bold">{{ statistics.noidentity_count }}</p>
            <p class="light">任课老师人数</p>
          </div>
          <p class="icon-box">
            <img src="@assets/images/teacher-icon3.png" alt="" />
          </p>
        </li>
      </ul>
      <div class="tool">
        <div class="search-form">
          <el-form inline :model="searchForm">
            <el-form-item style="flex-grow: 1">
              <el-input
                v-model.trim="searchForm.query_word"
                maxlength="100"
                placeholder="输入关键字"
              >
                <span slot="prefix" class="iconfont">&#xe61c;</span>
              </el-input>
            </el-form-item>
          </el-form>
          <el-button type="custom_primary" size="medium" @click="init('search')"
            >搜索</el-button
          >
          <el-button
            class="iconfont mini"
            type="custom_success"
            @click="searchForm.query_word = ''"
            circle
            >&#xe608;</el-button
          >
        </div>
        <div>
          <el-button type="custom_primary" size="medium" @click="teacherAdd">
            新增教师+
          </el-button>
          <el-button
            type="custom_primary"
            size="medium"
            @click="batchTeacherAdd"
          >
            批量导入
          </el-button>
        </div>
      </div>
    </div>
    <div class="table-box">
      <div class="table-inner" ref="tableInner">
        <el-table :data="statistics.data" height="100%">
          <el-table-column
            align="center"
            prop="teuse_name"
            label="教师姓名"
            min-width="11.78%"
          />
          <el-table-column
            align="center"
            prop="teuse_account"
            label="账号"
            min-width="20.39%"
          />
          <el-table-column
            align="center"
            prop="teuse_lev_title"
            label="教师评级"
            min-width="17.83%"
          />
          <el-table-column
            align="center"
            prop="teuse_province_city_area"
            label="省/市/区"
            min-width="23.25%"
          />
          <el-table-column align="center" label="操作" min-width="26.75%">
            <template slot-scope="scope">
              <el-button
                type="custom_success"
                plain
                size="mini"
                @click="
                  $router.push({
                    name: 'USER_TEACHER_DETAIL',
                    params: { id: scope.row.teuser_id },
                  })
                "
              >
                查看
              </el-button>
              <el-button
                type="custom_primary"
                plain
                size="mini"
                @click="teacherEdit(scope.row)"
              >
                编辑
              </el-button>
              <!-- <el-button type="custom_danger" plain size="mini" @click="handleDelete(scope.row)">
                                删除
                            </el-button> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="table-foot custom-foot">
        <customPagination
          :current="searchForm.pageindex"
          :total="total"
          @pageChange="flippingPage"
        />
      </div>
    </div>
    <el-dialog
      :title="(type == 'update' && '编辑信息') || '新增教师'"
      width="1034px"
      :visible.sync="teacherDialog"
      :show-close="false"
      @close="hideDialog"
    >
      <div class="dialog-container">
        <p class="tips">
          <i class="iconfont">&#xe63c;</i>
          账号默认密码：{{ statistics.teacher_default_password }}
        </p>
        <div class="form-wrapper">
          <el-form
            label-width="84px"
            ref="teacherForm"
            :rules="teacherRules"
            inline
            :model="teacherForm"
          >
            <el-form-item label="教师姓名" prop="teuse_name">
              <el-input
                v-model.trim="teacherForm.teuse_name"
                maxlength="100"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="账号" prop="teuse_account">
              <el-input
                v-model.trim="teacherForm.teuse_account"
                maxlength="11"
                :readonly="type == 'update'"
                placeholder="请输入"
              />
            </el-form-item>
            <el-form-item label="密码">
              <el-input
                v-model.trim="teacherForm.teuse_password"
                type="password"
                maxlength="16"
                :placeholder="
                  type == 'update'
                    ? '不更改则不输入(8~16位)'
                    : '不输入则为默认密码(8~16位)'
                "
              />
            </el-form-item>
            <el-form-item label="省/市/区" prop="district">
              <el-cascader
                v-model="teacherForm.district"
                clearable
                :options="province"
                :props="{ value: 'ddi_id', label: 'ddi_name' }"
                placeholder="请选择区域"
              />
            </el-form-item>
            <el-form-item label="教师评级" prop="teuse_lev_id">
              <el-select
                v-model="teacherForm.teuse_lev_id"
                placeholder="请选择"
              >
                <el-option label="正高级教师" :value="1" />
                <el-option label="高级教师" :value="2" />
                <el-option label="一级教师" :value="3" />
                <el-option label="二级教师" :value="4" />
                <el-option label="三级教师" :value="5" />
              </el-select>
            </el-form-item>
            <el-form-item class="class-distribution" label="年级/班级">
              <ul class="distribution-list">
                <li
                  class="distribution-item"
                  v-for="(item, index) in teacherForm.classData"
                  :key="index"
                >
                  <el-form inline :model="item" class="distribution-form">
                    <el-form-item>
                      <el-cascader
                        v-model="item.sccla"
                        :options="gradeClass"
                        :props="{ value: 'sccla_id', label: 'sccla_name' }"
                        collapse-tags
                      />
                    </el-form-item>
                    <el-form-item>
                      <el-select
                        v-model="item.sysub_id"
                        placeholder="请选择科目"
                      >
                        <el-option
                          :label="temp.sysub_name"
                          :value="temp.sysub_id"
                          v-for="temp in subjectList"
                          :key="temp.sysub_id"
                        />
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-select
                        v-model="item.teuse_ide_identity"
                        placeholder="请选择身份"
                      >
                        <el-option label="班主任 " :value="10" />
                        <el-option label="科任老师" :value="20" />
                      </el-select>
                    </el-form-item>
                  </el-form>
                  <el-button
                    class="normal del iconfont"
                    type="custom_primary"
                    circle
                    @click="delDistribution(index)"
                    >&#xe620;</el-button
                  >
                </li>
                <li class="distribution--add iconfont" @click="addDistribution">
                  &#xe603;
                </li>
              </ul>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div slot="footer" :class="[type == 'update' && 'foot-flex']">
        <!-- <el-button style="margin-right:auto" type="custom_warning" size="small" v-if="type == 'update'">
                    重置密码
                </el-button> -->
        <el-button type="custom_info" size="small" @click="hideDialog">
          取 消
        </el-button>
        <el-button
          type="custom_primary"
          size="small"
          @click="handleConfirm"
          v-loading="btnload"
          :disabled="btnload"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>

    <!-- 批量新建 -->
    <el-dialog
      title="批量新建"
      :visible.sync="batchImportDialog"
      width="560px"
      :show-close="false"
      @close="hideImportDialog()"
    >
      <div class="import-dialog">
        <p class="download-formwork">
          <el-button
            type="custom_primary"
            size="small"
            @click="downloadTemplate"
            >下载模板</el-button
          >
        </p>
        <el-upload
          :action="$upload.fileAction2"
          :headers="$upload.header"
          drag
          :show-file-list="ImportfileList.length==0" 
          :file-list="ImportfileList" 
          accept=".xls,.xlsx,.xlsm"
          :before-upload="
            (file) => {
              return $beforFileUpload(file, ['xls', 'xlsx', 'xlsm']);
            }
          "
          :on-success="uploadpractiseImportSuccess"
        >
          <p class="iconfont">&#xe603;</p>
          <p class="tips">拖拽或<span>点击上传</span></p>
        </el-upload>
        <ul class="file-list">
          <li
            class="file-item"
            v-for="(item, index) in ImportfileList"
            :key="index"
          >
            <span
              class="iconfont-color"
              v-html="fileType(item.name.split('.')[1])"
            />
            <p class="name line-text--1st">{{ item.name }}</p>
            <el-button
              class="iconfont mini"
              type="custom_primary"
              circle
              @click="deletepractiseUploadFile(index)"
            >
              &#xe620;
            </el-button>
          </li>
        </ul>
      </div>
      <div slot="footer">
        <el-button type="custom_info" size="small" @click="hideImportDialog()">
          取 消
        </el-button>
        <el-button
          type="custom_primary"
          size="small"
          @click="uploadTable"
          :disabled="uploadBtnload"
          v-loading="uploadBtnload"
        >
          确 定
        </el-button>
      </div>
    </el-dialog>
  </section>
</template>

<script>
import {
  teauserInfo,
  teauserRemove,
  teauserEdit,
  teauserAdd,
  teauserList,
  downloadTemplate,
  importTeacher,
} from "@api/user";
import customPagination from "@comp/customPagination";
import { mapState } from "vuex";
import { validPassword, validPhone } from "@utils/validate";
import { formatFileType } from "@utils";
export default {
  name: "user_teacher",
  components: {
    customPagination,
  },

  data() {
    // 电话号码校验
    let validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入教师账号,账号为教师手机号码"));
      } else if (!validPhone(value)) {
        callback(new Error("请输入正确的教师账号,账号为教师手机号码"));
      } else {
        callback();
      }
    };
    return {
      batchImportDialog: false,
      fielUrl: "",
      ImportfileList: [],
      searchForm: {
        pageindex: 1,
        pagesize: 10,
        teuse_examine: 20,
      },
      total: 0,
      type: "add",
      statistics: {},
      teacherDialog: false,
      teacherForm: {
        classData: [
          {
            sccla: [],
            sysub_id: "",
            teuse_ide_identity: "",
          },
        ],
      },
      btnload: false,
      uploadBtnload: false,
      teacherRules: {
        teuse_name: [
          { required: true, message: "请输入姓名", trigger: "blur" },
        ],
        teuse_account: [
          { required: true, validator: validatePhone, trigger: "blur" },
        ],
        teuse_lev_id: [
          { required: true, message: "请选择教师评级", trigger: "change" },
        ],
        district: [
          {
            type: "array",
            required: true,
            message: "请选择地区",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("common", ["province", "gradeClass", "subjectList"]),
    /* 文件类型 */
    fileType() {
      return function (val) {
        return formatFileType(val);
      };
    },
  },
  created() {
    this.init();
  },
  methods: {
    /** 切换页码 */
    flippingPage(val) {
      this.searchForm.pageindex = val;
      this.init();
    },
    /**初始化 */
    async init(val) {
      if (val == "search") this.searchForm.pageindex = 1;
      const res = await teauserList(this.searchForm);
      if (res.status == 200) {
        this.total = res.data.allcount;
        this.statistics = res.data;
      }
    },
    handleDelete(row) {
      this.$msgbox({
        title: "删除",
        message: "确认删除当前教师？",
        type: "warning",
        showClose: false,
        showCancelButton: true,
        cancelButtonClass: "el-button--custom_info",
        confirmButtonClass: "el-button--custom_primary",
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(async () => {
          const res = await teauserRemove({ id: row.teuser_id });
          if (res.status == 200) {
            this.$message({
              type: "success",
              duration: 1500,
              message: "教师已删除！",
            });
            this.total = (this.total - 1 < 0 && 0) || this.total - 1;
            if (this.tableData.length == 1 && this.searchForm.pageindex != 1) {
              this.searchForm.pageindex = this.searchForm.pageindex - 1;
            }
            this.init();
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            duration: 1500,
            message: "操作已取消",
          });
        });
    },
    /** 添加管理班级*/
    addDistribution() {
      const obj = {
        sccla: [],
        sysub_id: "",
        teuse_ide_identity: "",
      };
      this.teacherForm.classData.push(obj);
      this.$forceUpdate();
    },
    /** 移除管理班级 */
    delDistribution(index) {
      let classData = this.teacherForm.classData;
      this.$_.pullAt(classData, index);
      this.teacherForm.classData = [...classData];
      this.$forceUpdate();
    },
    /** 添加教师 */
    teacherAdd() {
      this.type = "add";
      this.teacherForm = {
        classData: [
          {
            sccla: [],
            sysub_id: "",
            teuse_ide_identity: "",
          },
        ],
      };
      this.teacherDialog = true;
      this.$nextTick(() => {
        this.$refs.teacherForm.clearValidate();
      });
    },
    /** 批量导入 */
    batchTeacherAdd() {
      this.fielUrl = [];
      this.ImportfileList = [];
      this.batchImportDialog = true;
    },
    deletepractiseUploadFile(){
        this.fielUrl = [];
      this.ImportfileList = [];
    },
    /** 关闭导入弹窗*/
    hideImportDialog() {
      this.batchImportDialog = false;
    },
    /** */
    async uploadTable() {
      if (this.$isEmpty(this.fielUrl))
        return this.$message.warning("请上传需要导入的文件");
      this.uploadBtnload = true;
      let params = {
        inportfile: this.fielUrl,
      };
      this.fileList = [];
      let res = await importTeacher(params);
      this.uploadBtnload = false;
      if (res) {
        this.init();
        if (res.data.errorNum) {        
          this.$message.warning(
            `共导入${res.data.allCount}条，导入失败${res.data.errorNum}条`
          );
        } else {
          this.$message.success(`成功导入${res.data.allCount}条`);
        }

        if (res.data.errorNum && res.data.errorFileUrl){
            this.$fileDownload(res.data.errorFileUrl)
        };
        this.batchImportDialog = false;
      }
    },
    async downloadTemplate() {
      let { data } = await downloadTemplate();
      this.$fileDownload(data.full_file_url);
    },
    /** */
    uploadpractiseImportSuccess({ data, msg }, { name }) {
      if (this.$isEmpty(data)) return this.$message.error(msg);
      this.fielUrl = data.address;
      let courseware = { name, type: data.file_type };
      this.ImportfileList = [courseware];
      console.log(this.ImportfileList);
      this.$forceUpdate();
    },
    /** 编辑教师 */
    async teacherEdit(row) {
      this.type = "update";
      const res = await teauserInfo({ teuser_id: row.teuser_id });
      let classData = [];
      res.data.classData.map((item) => {
        let class_item = {
          sysub_id: item.sysub_id,
          sccla: [item.teuse_ide_grade, item.sccla_id],
          teuse_ide_identity: item.teuse_ide_identity,
          teuse_ide_id: item.teuse_ide_id,
        };
        classData.push(class_item);
      });
      if (res.status == 200) {
        this.teacherForm = {
          ...res.data,
          teuse_lev_id: (res.data.teuse_lev_id && res.data.teuse_lev_id) || "",
          classData: [...classData],
          district: [
            res.data.teuse_province,
            res.data.teuse_city,
            res.data.teuse_area,
          ],
        };
        this.teacherDialog = true;
      }
    },
    /** 确认添加 */
    async handleConfirm(val) {
      this.$refs.teacherForm.validate(async (valid) => {
        if (valid) {
          this.btnload = true;
          let params = {
            teuse_name: this.teacherForm.teuse_name,
            teuse_account: this.teacherForm.teuse_account,
            teuse_password: this.teacherForm.teuse_password,
            teuse_province: this.teacherForm.district[0],
            teuse_city: this.teacherForm.district[1],
            teuse_area: this.teacherForm.district[2],
            teuse_lev_id: this.teacherForm.teuse_lev_id,
            classData: [],
          };
          this.teacherForm.classData.map((item) =>
            params.classData.push({
              sysub_id: item.sysub_id,
              sccla_id: item.sccla_id,
              teuse_ide_identity: item.teuse_ide_identity,
              sccla_grade: item.sccla[0],
              sccla_id: item.sccla[1],
              teuse_ide_id: item.teuse_ide_id ? item.teuse_ide_id : "",
            })
          );
          let res = {};
          if (this.type == "add") {
            res = await teauserAdd(params);
          } else {
            params.teuser_id = this.teacherForm.teuser_id;
            res = await teauserEdit(params);
          }
          this.btnload = false;
          if (res.status == 200) {
            this.$message.success(this.type == "add" ? "添加成功" : "编辑成功");
            this.hideDialog();
            this.init();
          }
        }
      });
    },
    /** 关闭弹窗 */
    hideDialog() {
      this.teacherDialog = false;
      this.$refs.teacherForm.resetFields();
      this.teacherForm = {};
    },
  },
};
</script>
